.checkout-1-con {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
input {
  transition: all 0.25s linear;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input {
  outline: none;
}

.checkout-header .breadcrumb {
  color: #7d7d7d;
}

.checkout-header .breadcrumb li {
  float: left;
  padding: 0 6px;
}

.checkout-header .breadcrumb li:first-child {
  padding-left: 2px;
}

.checkout-header .breadcrumb li:not(:last-child)::after {
  content: " \276f";
  padding-left: 8px;
}

.checkout-header .count {
  float: right;
}

/* --- PRODUCT LIST --- */
.products {
  border-top: 1px solid #ddd;
}

.products > li {
  padding: 1rem 0;
  border-bottom: 1px solid #ddd;
}

.row {
  position: relative;
  overflow: auto;
  width: 100%;
}

.col,
.quantity,
.remove {
  float: left;
}

.col.left {
  width: 70%;
}

.col.right {
  width: 30%;
  position: absolute;
  right: 0;
  top: calc(50% - 30px);
}

.detail {
  padding: 0 0.5rem;
  line-height: 2.2rem;
}

.detail .name {
  font-size: 1.2rem;
}

.detail .description {
  color: #7d7d7d;
  font-size: 1rem;
}

.detail .price {
  font-size: 1.5rem;
}

.quantity,
.remove {
  width: 50%;
  text-align: center;
}

.remove svg {
  width: 60px;
  height: 60px;
}

.quantity > input {
  display: inline-block;
  width: 60px;
  height: 60px;
  position: relative;
  left: calc(50% - 30px);
  background: #fff;
  border: 2px solid #ddd;
  color: #7f7f7f;
  text-align: center;
  font: 600 1.5rem Helvetica, Arial, sans-serif;
}

.quantity > input:hover,
.quantity > input:focus {
  border-color: #f58551;
}

.close {
  fill: #7d7d7d;
  transition: color 150ms linear, background-color 150ms linear,
    fill 150ms linear, 150ms opacity linear;
  cursor: pointer;
}

.StripeElement {
  padding: 0px 0px 8px 4px;
  font-size: 14px;
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  /* box-shadow: 0px 1px 4px rgba(0,0,0,0.25); */
  width: 100%;
  margin-top: 3px;
}

.card_label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close:hover {
  fill: #f58551;
}

/* --- SUMMARY --- */
.promotion,
.summary,
.checkout {
  float: left;
  width: 100%;
  margin-top: 1.5rem;
}

.promotion > label {
  float: left;
  width: 100%;
  margin-bottom: 1rem;
}

.promotion > input {
  float: left;
  width: 80%;
  font-size: 1rem;
  padding: 0.5rem 0 0.5rem 1.8rem;
  border: 2px solid #16cc9b;
  border-radius: 2rem 0 0 2rem;
}

.promotion:hover > input {
  border-color: #f58551;
}

.promotion > button {
  float: left;
  width: 20%;
  padding: 0.5rem 0;
  border-radius: 0 2rem 2rem 0;
}

.promotion:hover > button {
  border-color: #f58551;
  background-color: #f58551;
}

.promotion > button::after {
  content: "\276f";
  font-size: 1rem;
}

.summary {
  font-size: 1.2rem;
  text-align: right;
}

.summary ul li {
  padding: 0.5rem 0;
}

.summary ul li span {
  display: inline-block;
  width: 30%;
}

.summary ul li.total {
  font-weight: bold;
}

.checkout {
  text-align: right;
}

.checkout > button {
  font-size: 1.2rem;
  padding: 0.8rem 2.8rem;
  border-radius: 1.5rem;
}

.empty-product {
  text-align: center;
}

.empty-product > button {
  font-size: 1.3rem;
  padding: 10px 30px;
  border-radius: 5px;
}

.summary-accordion {
  min-height: 35px !important;
}

.summary-accordion .MuiAccordionSummary-content {
  margin: 0 !important;
  display: flow !important;
}

/* --- SMALL SCREEN --- */
@media all and (max-width: 599px) {
  .thumbnail img {
    display: none;
  }

  .quantity > input {
    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
  }

  .remove svg {
    width: 40px;
    height: 40px;
  }
}

/* --- MEDIUM & LARGE SCREEN --- */
@media all and (min-width: 600px) {
  html {
    font-size: 14px;
  }

  .container {
    width: 75%;
    max-width: 960px;
  }

  .thumbnail,
  .detail {
    float: left;
  }

  .thumbnail {
    width: 35%;
  }

  .detail {
    width: 65%;
  }

  .promotion,
  .summary {
    width: 50%;
  }

  .checkout {
    width: 100%;
  }

  .checkout,
  .summary {
    text-align: right;
  }
}

.payment-con {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1rem 0;
}

.payment-con .payment-form {
  width: 65%;
  border-right: 1px solid #ddd;
  margin-right: 1rem;
  padding-right: 1rem;
}

.payment-con .payment-summary {
  width: 35%;
}

.checkout-file-upload-con {
  /* background: var(--primary-color); */
  /* padding: 2rem; */
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.checkout-docusign-con {
  min-height: 200px;
  width: 100%;
  display: flex;
  margin: auto;
  background-color: #fcefde;
  border-radius: 30px;
}

/* --- LARGE SCREEN --- */
@media all and (min-width: 992px) {
  html {
    font-size: 16px;
  }
}

/* CREDIT CARD CSS  */
.credit-card {
  width: 500px;
  height: 300px;
  color: #fff;
  cursor: pointer;
  perspective: 1000px;
  margin: auto;
}

.credit-card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform .75s;
  transform-style: preserve-3d;
  background: var(--black-color);
  border-radius: 10px;
}

.credit-card .front,
.back {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 30px;
  border-radius: 15px;
  overflow: hidden;
  z-index: 1;
  backface-visibility: hidden;
}

.credit-card .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.credit-card .map-img {
  width: 100%;
  position: absolute;
  top: -6rem;
  left: 0;
  opacity: 0.75;
  z-index: -1;
}

.credit-card .card-no {
  font-size: 28px;
  margin-top: 60px;
}

.credit-card .card-holder {
  font-size: 12px;
  margin-top: 40px;
}

.credit-card .name {
  font-size: 18px;
}

.credit-card .bar {
  background: #222;
  margin-left: -30px;
  margin-right: -30px;
  height: 60px;
  margin-top: 10px;
}

.credit-card .card-cvv {
  margin-top: 20px;
}

.credit-card .card-cvv div {
  flex: 1;
}

.credit-card .card-cvv img {
  width: 100%;
  display: block;
  line-height: 0;
}

.credit-card .card-cvv p {
  background: #fff;
  color: #000;
  font-size: 18px;
  padding: 10px 20px;
}

.credit-card .card-text {
  margin-top: 30px;
  font-size: 14px;
}

.credit-card .signature {
  margin-top: 20px;
}

.credit-card .back {
  transform: rotateY(180deg);
}

.credit-card:hover .credit-card-inner {
  transform: rotateY(-180deg);
}
