.adm-sidebar {
  /* position: absolute;
  top: 4.75rem;
  left: 0;
  bottom: 0;
  width: 200px;
  padding: 20px;
  background-color: var(--black-color);
  color: var(--white-color);
  transition: transform 0.5s ease;
  z-index: 1;
  overflow-y: hidden;
  height: 100%; */

  background-color: var(--black-color);
  bottom: 0;
  color: var(--white-color);
  left: 0;
  overflow-y: auto;
  padding: 20px;
  position: fixed;
  top: 4.75rem;
  transition: transform 0.5s ease-in-out;
  width: 240px;
  z-index: 10000;
}

.adm-sidebar.collapsed {
  transform: translateX(-240px);
  width: 240px;
  /* padding: 20px 0; */
}

.adm-sidebar .logo {
  font-size: 24px;
  /* margin-bottom: 20px; */
  color: var(--peel-orange-color);
  padding: 10px;
  text-align: center;
}

.adm-sidebar .navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.adm-sidebar .navigation ul li {
  margin-bottom: 10px;
}

.adm-sidebar .navigation ul li a {
  color: #fff;
  display: block;
  padding: 10px;
  text-decoration: none;
  width: 100%;
}

.adm-sidebar .navigation ul li a:hover {
  color: #ddd;
}

.user-info {
  padding: 10px;
  text-align: center;
}

.active_admin {
  color: var(--primary-color) !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 1050px) {
  .adm-sidebar {
    top: 4rem;
  }
}
