.flow > * + * {
  margin-top: var(--flow-space, 1em);
}

/* CARD COMPONENT */

.sm-car-card {
  display: grid;
  place-items: center;
  /* width: 80vw; */
  /* max-width: 21.875rem; */
  /* height: 28.125rem; */
  max-width: 340px;
  max-height: 340px;
  width: 340px;
  height: 340px;
  overflow: hidden;
  border-radius: 0.625rem;
  /* box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25); */
}

.sm-car-card > * {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.card__background {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.card__content {
  --flow-space: 0.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-end;
  height: 55%;
  padding: 12% 1.25rem 1.875rem;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.3) 10%,
    hsl(0, 0%, 0%) 100%
  );
  color: var(--white-color);
  width: 100%;
}

.card__content--container {
  --flow-space: 1.25rem;
}

.card__title {
  position: relative;
  width: fit-content;
  width: -moz-fit-content; /* Prefijo necesario para Firefox  */
}

.card__title::after {
  content: "";
  position: absolute;
  height: 0.3125rem;
  width: calc(100% + 1.25rem);
  bottom: calc((1.25rem - 0.5rem) * -1);
  left: -1.25rem;
  background-color: var(--primary-color);
}

.card__button {
  padding: 0.75em 1.6em;
  width: fit-content;
  width: -moz-fit-content; /* Prefijo necesario para Firefox  */
  font-variant: small-caps;
  font-weight: bold;
  border-radius: 0.45em;
  border: none;
  background-color: var(--primary-color);
  /* font-family: var(--font-title); */
  font-size: 1.125rem;
  color: var(--black-color);
}

.card__button:focus {
  outline: 2px solid black;
  outline-offset: -5px;
}

.category-pill {
  width: 255px;
  border-radius: 50px;
  height: 95px;
}

.category-pill img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  transform: scale(1.3);
}

@media (any-hover: hover) and (any-pointer: fine) {
  .card__content {
    transform: translateY(45%);
    transition: transform 250ms ease-out;
    transition-delay: 250ms;
  }

  .card__title::after {
    opacity: 0;
    transform: scaleX(0);
    transition: opacity 250ms ease-in, transform 250ms ease-out;
    transition-delay: 250ms;
    transform-origin: right;
  }

  .card__background {
    transition: transform 250ms ease-in;
    height: 340px;
  }

  .card__content--container > :not(.card__title),
  .card__button {
    opacity: 0;
    transition: transform 250ms ease-out, opacity 250ms ease-out;
  }

  .sm-car-card:hover,
  .sm-car-card:focus-within {
    transform: scale(1.05);
    transition: transform 250ms ease-in;
  }

  .sm-car-card:hover .card__content,
  .sm-car-card:focus-within .card__content {
    transform: translateY(0%);
    transition: transform 250ms ease-in;
    width: 100%;
    color: var(--white-color);
  }

  .sm-car-card:focus-within .card__content {
    transition-duration: 0ms;
  }

  .sm-car-card:hover .card__background,
  .sm-car-card:focus-within .card__background {
    transform: scale(1.3);
  }

  .sm-car-card:hover .card__content--container > :not(.card__title),
  .sm-car-card:hover .card__button,
  .sm-car-card:focus-within .card__content--container > :not(.card__title),
  .sm-car-card:focus-within .card__button {
    opacity: 1;
    transition: opacity 250ms ease-in;
    transition-delay: 500ms;
  }

  .sm-car-card:hover .card__title::after,
  .sm-car-card:focus-within .card__title::after {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left;
    transition: opacity 250ms ease-in, transform 250ms ease-in;
    transition-delay: 250ms;
  }
}


.category-pill {
  width: 100%;
  height: auto;
  border-radius: 50px;
  overflow: hidden;
}

.category-pill img, .category-image-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .category-image-card {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .category-image-card {
    width: 90%;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .category-image-card {
    width: 80%;
  }
}

