/* App.css */
.setup-profile-con {
  min-height: 100vh;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.css-1nmdiq5-menu {
  z-index: 999 !important;
  display: block;
  opacity: 1;
}

.css-ztjrsb-Input {
  padding: 12px 0 !important;
}

.custom-file-input {
  width: 110px;
  margin-top: 0.5rem;
  /* margin: 1rem 0; */
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Upload Image";
  display: inline-block;
  background: var(--secondary-color);
  /* border: 1px solid #999; */
  border-radius: 3px;
  padding: 8px 12px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  color: var(--white-color);
  /* text-shadow: 1px 1px #fff; */
  /* font-weight: 700; */
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
