.founder_container_signup{
 width:100%;
 text-align: center;
 /* margin: 3rem 0rem; */
 /* margin-top: 6rem; */
}

.founder_container_signup img{
    width: 40%;
    display: inline-block;
    margin: auto;
    border-radius: 10px;
}

.founder_container_signup .quote,.founder_container_signup .founder_position{
    margin-top:40px;
    margin-bottom:10px;
    text-align: center;
    font-style: italic;
}

@media screen and (max-width: 750px) {
    .founder_container_signup{
        width: 100%;
        margin-top: 1rem;
    }
}