.category-image-card {
  position: relative;
  max-width: 270px;
  max-height: 270px;
  overflow: hidden;
  border-radius: 20px;
  margin: .5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.category-image-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s;
}

.category-image-card h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  margin: 0;
  font-size: 1.5rem;
  z-index: 2;
}

.category-image-card:hover img {
  transition: 0.5s ease-in;
  transform: scale(1.2);
}

@media screen and (max-width: 500px) {
  .category-image-card {
    max-width: 320px !important;
    max-height: 320px !important;
  }
}
