.admin-panel-test {
  position: relative;
}

.toggle-button {
  background-color: var(--grey-color);
  color: var(--white-color);
  border: none;
  padding: 10px;
  cursor: pointer;
}

.admin-body-test {
  display: flex;
  flex-grow: 1;
  margin-top: 75px;
}

.sidebar {
  width: 240px;
  height: 100vh;
  /* height: calc(100vh - 10px); */
  background-color: var(--black-color);
  color: var(--white-color);
  transition: transform 0.3s ease;
  transform: translateX(-240px);
  position: fixed;
  top: 0px;
  left: 0;
  overflow: hidden;
}

.sidebar.open {
  transform: translateX(0);
}

.main-content {
  /* display: flex; */
  flex-grow: 1;
  margin: 0 10px;
  transition: margin-left 0.3s ease;
}

.main-content.shifted {
  margin-left: 240px;
  margin-right: 0;
}

@media (max-width: 768px) {
  .sidebar {
    width: 200px;
    transform: translateX(-200px);
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .main-content.shifted {
    margin-left: 200px;
  }
}
