.info_container {
  display: flex;
  gap: 2rem;
  width: 100%;
  margin: auto;
  /* margin: 2rem 0rem; */
  justify-content: center;
  align-items: center;
}

.info_img video {
  /* width: 100%; */
  width: 500px;
  max-width: 500px;
  /* border-radius: 16px; */
}

@media screen and (max-width: 1150px) {
  .info_container {
    width: 100%;
    padding: 0 2rem;
  }
}

@media screen and (max-width: 1000px) {
  .info_container {
    flex-direction: column;
  }
  .info_img img {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .info_img {
    max-width: 500px;
    margin: auto;
    text-align: center;
  }
  .info_img video {
    width: 100% !important;
    max-width: 320px !important;
  }
}
