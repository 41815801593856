.text-con {
  position: absolute;
  top: 32%;
  left: 5%;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgb(2 0 36 / 0%) 0%,
    rgb(25 118 210) 0%,
    rgba(37, 42, 44, 0.28) 100%
  );
}

.hero_container {
  background-image: linear-gradient(
      0,
      rgba(255, 255, 255, 0),
      rgba(139, 139, 139, 0)
    ),
    url("../../assets/Pictures/about-4.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  /* opacity: 0.5; */
  width: 100%;
}

.hero-input-con {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 1rem; */
  /* margin: auto; */
  /* background: #eaeaeabe; */
  border-radius: 10px;
  /* background: rgb(233, 233, 233); */
  /* background: linear-gradient(
    240deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.85) 100%
  ); */
}

.elastic-search-res {
  min-width: 350px;
  position: absolute;
  list-style: none;
  background: #f1f1f1;
  list-style-type: none;
  padding: 0.5rem;
  border-radius: 5px;
  z-index: 9999999;
  margin: 0 0.5rem;
}

.elastic-search-res .search-item {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 10px;
}

.elastic-search-res .search-item:hover {
  background: #e9e7e7;
}

@media screen and (max-width: 950px) {
  .hero-input-con {
    flex-direction: column;
  }
  .hero_container {
    height: 75vh;
  }
}
@media screen and (max-width: 900px) {
  .google-places-autocomplete {
    width: 320px !important;
  }
}

@media screen and (max-width: 640px) {
  .hero_container {
    height: 75vh;
  }
}

@media screen and (max-width: 500px) {
  .hero_container {
    height: 100vh;
    padding: 1rem;
  }
  .hero-input-con {
    padding: 1rem 0rem;
  }


}
