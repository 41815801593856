.my-prod-con {
  padding: 1rem;
}

.edit-profile {
  opacity: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  cursor: pointer;
  transition: opacity 0.5s ease-in;
}

.profile {
  /* position: relative;    */
  transition: all 0.5s ease-in;
}

.profile:hover img {
  opacity: 0.3;
}

.profile:hover .edit-profile {
  opacity: 1;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.reference-section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.reference-section label {
  margin-right: 10px;
}

.reference-section input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  background-color: #f7f7f7;
  font-size: 16px;
  flex-grow: 1;
}

.reference-section button {
  padding: 10px 10px;
  background-color: var(--black-color);
  color: white;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 16px;
}

.reference-section button:hover {
  background-color: var(--black-color);
}

.referral-input-field {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.profile-tab {
  color: var(--grey-color);
  font-weight: normal;
  align-items: flex-start;
  padding: 10px;
}

.tab-no-padding-imp {
  padding: 0 !important;
}
