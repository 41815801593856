@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,600|Roboto+Mono:300,400");
* {
  box-sizing: border-box;
}

.error-main {
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.55)),
    url("../../assets/Pictures/404-1.jpg");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 50px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-body {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* margin-top: 2rem; */
}

.error-body .error-title {
  /* display: flex; */
  /* font-family: "Montserrat", sans-serif; */
  font-weight: 700;
  font-size: 6rem;
  letter-spacing: 10px;
  color: #ffffff;
}

.error-body .error-desc {
  /* display: flex; */
  /* font-family: "Montserrat", sans-serif; */
  font-size: 3rem;
  letter-spacing: 10px;
}

.error-foot {
  /* font-family: "Montserrat", sans-serif; */
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  position: absolute;
  bottom: 2rem;
  width: 100%;
}
.hero-foot ul li {
  display: inline;
  padding: 25px;
}
.hero-foot a {
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.7rem;
}
.text-subscribe {
  color: #fff !important;
  text-align: center;
}

/* -------------------- */
/* -- Animations CSS -- */
/* -------------------- */
@keyframes fadeIn {
  from {
    opacity: 0;
    opacity: 1\9;
  }
  to {
    opacity: 1;
  }
}
.fade {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

.fade.fadeOne {
  animation-delay: 0.5s;
}

.fade.fadeTwo {
  animation-delay: 1.2s;
}

.fade.fadeThree {
  animation-delay: 2s;
}
.fade.fadeThree span {
  font-size: 0.8rem;
}

::placeholder {
  color: #ddd;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #ddd;
}
