.adm-right-section {
  padding: 0 10px;
  /* margin-left: 200px; */
  transition: margin 0.5s ease;
  z-index: 0;
  /* padding-top: 80px; */
}

.adm-right-section.collapsed {
  padding: 0;
  /* margin-top: 75px; */
  /* transition: all .3s ease-in-out; */
}

@media screen and (max-width: 1050px) {
  .adm-right-section.collapsed {
    margin: 0;
    /* margin-top: 6.5rem; */
  }
  .adm-right-section {
    margin-left: 0;
    padding: 10px;
  }
}
