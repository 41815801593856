.founder_container{
 width:100%;
 text-align: center;
 margin: 3rem 0rem;
 /* margin-top: 6rem; */
}

.founder_container img{
    width: 50%;
    display: inline-block;
    margin: auto;
    border-radius: 10px;
}

.founder_container .quote,.founder_container .founder_position{
    margin-top:40px;
    margin-bottom:10px;
    text-align: center;
    font-style: italic;
}

@media screen and (max-width: 750px) {
    .founder_container{
        width: 100%;
        margin-top: 1rem;
    }
}