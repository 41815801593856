.testimonials-container {
  padding: 0 2rem;
}
.client-img {
  padding-right: 15px;
}
.client-info {
  position: relative;
  z-index: 3;
  margin-top: 2rem;
}
.client-info a {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  font-size: 22px;
}
.client-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  /* padding-bottom: 50px; */
}
.client-info h6 {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 16px;
}
.client-info span {
  /* display: inline-block; */
  color: #666;
  font-size: 12px;
}

.client-info .client-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

@media screen and (max-width: 767px) {
  .testimonials-container {
    padding: 0;
    margin: 1rem;
  }
}
