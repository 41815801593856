.notif-card {
  background: var(--black-color);
  display: flex; /* Add flex to arrange children horizontally */
  align-items: stretch; /* Ensures children have the same height */
  padding: 0.5rem 0;
  border-radius: 10px;
  padding: 1rem;
}

.border-box {
  width: 10px; /* Same as the earlier border-left width */
  background: var(--white-color); /* Border color */
  border-radius: 20px; /* Add curved ends for the border */
}

.notif-card-wrapper {
  background: var(--black-color);
  color: var(--white-color);
  display: flex;
  padding-left: 1rem;
  flex-grow: 1;
  /* align-items: flex-end; */
  position: relative;
  border-radius: 0 10px 10px 0; /* Match the border-box curve */
  border-radius: 10px;
}

.notif-btns {
  display: flex;
  flex-direction: column;
}

.notif-image {
  position: relative;
  width: 250px;
  height: 150px;
}

.notif-image img {
 width: 100%;
 height: 100%;
 object-fit: cover;
}

.notif-details {
  flex: 1 1 70%; /* 70% width in larger screens */
  padding-right: 1rem;
}

.notif-side {
  flex: 1 1 30%; /* 30% width in larger screens */
}

@media screen and (max-width: 1080px) {
  .notif-details {
    flex: 100%; /* 70% width in larger screens */
    padding-right: 0rem;
  }
  .notif-side {
    flex: 100%; /* 30% width in larger screens */
  }
}

@media screen and (max-width: 768px) {
  .notif-card-wrapper {
    flex-direction: column;
  }
}
