.css-nnid7-MuiSlider-valueLabel {
  font-size: 10px;
  line-height: 1;
  padding: 5px 8px;
}
/* .css-eg0mwd-MuiSlider-thumb {
  width: 12px !important;
  height: 12px !important;
} */

.css-gr2b9o-MuiSlider-root {
  width: 92% !important;
  margin: auto !important;
  display: flex !important;
}

/* .css-6od3lo-MuiChip-label {
  color: var(--white-color) !important;
} */

.menu,
.drop-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  display: inline-block;
  background-color: transparent;
  position: relative;
}

.menu-item a {
  text-decoration: none;
  padding: 8px 12px;
  color: var(--black-color);
  display: block;
}

.drop-menu .drop-menu-item {
  font-size: 16px;
}

.drop-menu {
  display: none;
  border-radius: 5px;
  z-index: 9999;
  position: absolute;
  right: 0rem;
  background-color: var(--white-color);
  min-width: 210px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.drop-menu-item {
  width: 100%;
}

.drop-menu-item:hover {
  background-color: #eee;
  border-radius: 5px;
}

.drop-menu-item a {
  color: var(--black-color);
}

.menu-item:hover .drop-menu {
  display: block;
}

@media screen and (max-width: 900px) {
  .css-1qhmq6t-MuiGrid-root {
    width: 100% !important;
  }
}
