@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.css-1igyujy-MuiContainer-root {
  padding: 0 !important;
}

* {
  /* transition: ease 0.1s; */
  margin: 0;
  outline: none;
  scrollbar-color: #333 #999;
  scrollbar-width: thin !important;
  scroll-behavior: smooth;
  font-family: "Helvetica", Arial, "sans-serif";
}

*::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}
*::-webkit-scrollbar-track {
  background: #dddddd;
  /* border-radius: 5px; */
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(136, 136, 136);
  border-radius: 5px;
}

a {
  text-decoration: none !important;
  /* color: var(--black-color); */
  /* color: inherit !important; */
  /* color: var(--dark-grey-color); */
}
a:active, a:focus, a:hover {
  text-decoration: none !important;
}
:root {
  --rs-primary: #f3a950 !important; /* Change this to your desired primary color */
  --rs-input-focus-border: #f3a950 !important;
  --rs-bg-active: #f3a950 !important;
  --rs-calendar-range-bg: rgb(248, 222, 191) !important;
  --rs-listbox-option-hover-bg: rgb(248 234 217) !important;
  --rs-listbox-option-hover-text: #f3a950 !important;
  --rs-listbox-option-selected-text: #f3a950 !important;
  --rs-calendar-cell-selected-hover-bg: #f3a950 !important;
  --rs-btn-link-text: var(--rs-bg-active) !important;
  --rs-btn-link-hover-text: var(--rs-bg-active) !important;
  --rs-btn-primary-bg: #f3a950 !important;
  --rs-btn-primary-hover-bg: #f3aa50ba !important;
  --rs-btn-primary-hover-bg: #f3aa50ba !important;

  --primary-color: #f3a950;
  --secondary-color: #f05023;
  --white-color: #fff;
  --black-color: #161b21;
  --grey-color: #999;
  --dark-grey-color: #2a2a2a;
  --toastify-color-dark: #161b21 !important;
  --toastify-color-progress-dark: #008353 !important;
  --toastify-color-progress-info: var(--toastify-color-progress-dark);
  --toastify-color-progress-success: #008353 !important;
  --toastify-color-progress-warning: var(--toastify-color-progress-dark);
  --toastify-color-progress-error: var(--toastify-color-error);
  --toastify-icon-color-success: #008353 !important;

  /* AG GRID CUSTOMIZATION */
  --ag-material-primary-color: #f05023 !important;
  --ag-material-accent-color: #f05023 !important;
  --ag-foreground-color: #2a2a2a !important;
}

.ag-theme-material {
  /* --ag-foreground-color: var(--grey-color) !important;
  --ag-background-color: rgb(249, 245, 227) !important;
  --ag-header-foreground-color: var(--black-color) !important;
  --ag-header-background-color: #f1f1f1 !important;
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03) !important;
  --ag-header-column-resize-handle-color: rgb(126, 46, 132) !important; */

  /* --ag-font-size: 17px;
  --ag-font-family: monospace; */
}

/* .ag-theme-material .ag-selection-checkbox .ag-icon-checkbox-unchecked,
.ag-theme-material .ag-selection-checkbox .ag-icon-checkbox-partial,
.ag-theme-material .ag-selection-checkbox .ag-icon-checkbox-checked {
  color: #4caf50 !important; /* Change this to your desired color */
/* } */

/* .ag-checkbox-input-wrapper.ag-checked::before {
  background-color: #161b21 !important;
  background-image: none !important;
} */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/* .Toastify__progress-bar--success {
  background: #f3a950 !important;
} */
.Toastify__toast-theme--light {
  background: var(--black-color) !important;
  color: var(--white-color) !important;
}

.Toastify__close-button--light {
  color: var(--white-color) !important;
}

.Toastify__toast-container--top-right {
  top: 4em !important;
  right: 0 !important;
}

.page-container {
  max-width: var(--maxWidth);
  margin: auto;
  width: 100%;
}

.site-width {
  max-width: var(--maxWidth);
}

body {
  margin: 0px;
  padding: 0px;
}

.header {
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .header_left {
  display: flex;
  align-items: center;
}

.header .header_right {
  display: flex;
  align-items: center;
}

.input_search {
  padding: 8px;
  font-size: 14px;
  outline: none;
  border: 1px solid #e7e6ef;
  width: 370px;
}

.input_search:focus {
  border-color: #f26654;
}

.btn {
  padding: 9.5px 25px;
  background-color: #f17c6d;
  outline: none;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: 0.5s ease;
  border-radius: 3px;
}

.dis-btn {
  opacity: 0.5;
  cursor: not-allowed;
}

.dark-btn {
  background-color: #f26654;
  /* box-shadow: 1px 1px 2px rgba(0,0,0,0.5); */
}

.btn:hover {
  background-color: #f26654;
}

.dark-btn:hover {
  box-shadow: none;
}

.dark-btn:active {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.icon_container {
  color: #f26654;
  cursor: pointer;
  padding: 0px 15px;
  position: relative;
}

.icon_container i {
  font-size: 28px;
}

.badge {
  right: 10%;
  font-size: 8px;
  font-weight: bolder;
  display: inline-block;
  text-decoration: none;
  border: 1px solid;
  padding: 0px 2px;
  position: absolute;
  text-align: center;
}

.badge-bag {
  font-size: 8px;
  font-weight: bolder;
  display: inline-block;
  text-decoration: none;
  border: 1px solid;
  padding: 0px 2px;
  position: absolute;
}

.section_title {
  color: #506385;
  margin: 10px auto;
  text-align: center;
}

.paragraph_title {
  color: #506385;
  font-size: x-large;
  margin: 10px auto;
  padding-top: 40px;
  padding-bottom: 5px;
  text-align: center;
}

.view_btn {
  padding: 5px 15px;
  display: block;
  background-color: #08d15f;
  outline: none;
  border: none;
  color: #fff;
  cursor: pointer;
  margin: 5px auto 0px auto;
  transition: 0.5s ease;
}

.signup {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin:60px auto; */
  /* width: 90%; */
  justify-content: space-evenly;
  background-image: linear-gradient(
      rgba(124, 124, 124, 0.1),
      rgba(0, 0, 0, 0.6)
    ),
    url("../src/assets/Pictures/login-bg.png");
  background-position: center;
  /* background-image: url('../src/assets/Pictures/login-bg-1.jpg'); */
  background-size: cover;
  position: relative;
  padding: 2rem 0;
  min-height: 100vh;
}

.signup_right {
  width: 100%;
  max-width: 550px;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 29px 0px;
}

.small_header {
  display: none;
}

.small_header2 {
  display: block;
}

.badge_parent {
  background-color: var(--peel-orange-color);
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
  margin: 8px;
  width: fit-content;
}

#shadow-host-companion {
  padding: 0px;
}

.BrainhubCarousel__arrows {
  background-color: var(--primary-color) !important;
}

/* .MuiCardContent-root {
    background-color: var(--primary-color);
    border-radius: 0 0 10px 10px;
  } */

.BrainhubCarousel__container {
  margin-top: 2px;
}

.swal-modal {
  background: var(--black-color);
}
.swal-title {
  color: var(--white-color);
}
.swal-text {
  color: var(--grey-color);
}
.swal-button--confirm {
  background-color: var(--secondary-color) !important;
}

/* Target autofill styles for different states */
input {
  -webkit-background-clip: text !important;
}

.mont-title {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
}

.no-box-shadow {
  box-shadow: none !important;
}

.lliinnkk {
  color: blue !important;
  border-bottom: 1px solid blue !important;
  font-weight: 700 !important;
}

.black-link {
  color: #777 !important;
}

.MuiTooltip-tooltip {
  z-index: 9999 !important;
}

/* custom-theme.css */

/* Override font family globally */
.rs-dropdown.rs-dropdown-placement-bottom-start,
.rs-dropdown-menu,
.rs-dropdown-item,
.rs-dropdown-item.rs-dropdown-item-submenu,
.rs-dropdown-item-toggle.rs-dropdown-item {
  font-family: "Helvetica", Arial, "sans-serif" !important;
  /* background-color: var(--primary-color) !important; */
}
@media screen and (max-width: 900px) {
  .input_search {
    width: 300px;
  }
}

@media screen and (max-width: 800px) {
  .input_search {
    width: 260px;
  }
}

@media screen and (max-width: 750px) {
  .badge {
    font-size: 7px;
    right: -20%;
    margin-left: 10px;
  }
  .badge-bag {
    color: #fff;
    font-size: 7px;
  }
  .icon_container i {
    font-size: 20px;
  }
  .icon_container {
    /* padding:0px 10px; */
    padding: 0px;
    margin: 10px;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .signup {
    flex-direction: column;
    align-items: center;
  }

  .signup_right {
    width: 90%;
  }

  .small_header {
    display: block;
  }

  .small_header2 {
    display: none;
  }
}
