* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

button {
  cursor: pointer;
  border: none;
  background-color: unset;
}

.activated {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  border: 3px solid var(--primary-color);
  border-radius: 10px;
  background-color: #e3dfdfab;
}

@media (min-width: 769px) {
  .hide-in-desktop {
    display: none;
  }
}

@media (max-width: 768px) {
  .hide-in-mobile {
    display: none;
  }
}

.mobile-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.App {
  text-align: center;
}

header nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  border-bottom: 1px solid var(--grayish-blue);
  position: relative;
}
@media (max-width: 786px) {
  header nav {
    padding: 20px 0;
  }
}

header nav .left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

header nav .left .imgs {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  header nav .left .imgs {
    gap: 20px;
  }
}

header nav .left .imgs * {
  cursor: pointer;
}

header nav .left ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

header nav .left ul button {
  padding: 0;
  color: var(--d-grayish-blue);
  font-size: var(--p-fs);
}

header nav .left ul li {
  position: relative;
}

header nav .left ul li::before {
  content: "";
  position: absolute;
  top: 60px;
  left: 0;
  height: 3px;
  width: 100%;
  background-color: var(--primary-color);
  opacity: 0;
  transition: 0.2s;
}

header nav .left ul li:hover::before {
  opacity: 1;
}

header nav .left ul li:hover button {
  color: var(--vd-blue);
}

header nav .right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  z-index: 998;
}
@media (max-width: 768px) {
  header nav .right {
    gap: 20px;
  }
}

header nav .right img {
  width: 45px;
  cursor: pointer;
}
@media (max-width: 768px) {
  header nav .right img {
    width: 36px;
  }
}

header nav .right img.avatar:hover {
  border: 2px solid var(--primary-color);
  border-radius: 50%;
}

header nav .right svg {
  cursor: pointer;
}

header nav .right .cart {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  box-shadow: 1px 7px 20px -7px var(--d-grayish-blue);
  z-index: 999;
  background-color: #fff;
  width: 355px;
  border-radius: 7px;
}
@media (max-width: 768px) {
  header nav .right .cart {
    position: absolute;
    top: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 1px 7px 20px -7px var(--d-grayish-blue);
    z-index: 999;
    background-color: #fff;
    width: 80vw;
  }
}

header nav .right .cart .head {
  display: flex;
  align-items: flex-start;
  padding: 24px;
}
header nav .right .cart .head p {
  margin: 0;
  font-weight: 700;
}
header nav .right .cart hr {
  margin: 0;
  color: var(--l-grayish-blue);
}

header nav .right .cart .cart-content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: var(--d-grayish-blue);
  min-height: 160px;
}

header nav .right .cart .cart-content p.empty {
  font-weight: 700;
}

header nav .right .cart .product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

header nav .right .cart .product img {
  border-radius: 2px;
}

header nav .right .cart .product .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

header nav .right .cart .product .info > p {
  margin: 0;
  text-transform: capitalize;
  text-align: left;
}
@media (max-width: 768px) {
  header nav .right .cart .product .info > p {
    font-size: 15px;
  }
}

header nav .right .cart .product .info .price span:last-of-type {
  font-weight: 700;
  color: var(--vd-blue);
}
.discount {
  color: var(--white-color);
  background-color: var(--grey-color);
  padding: 3px 10px;
  border-radius: 5px;
  font-weight: 700;
}

.avl-for-sale {
  color: var(--white-color);
  background-color: #ffb100;
  padding: 3px 10px;
  border-radius: 5px;
  font-weight: 700;
}
.avl-for-rent {
  color: var(--white-color);
  background-color: var(--secondary-color);
  padding: 3px 10px;
  border-radius: 5px;
  font-weight: 700;
}

header nav .right .cart .product .delete-button img {
  height: 20px;
  width: 16px;
}

header nav .right .cart button.checkout {
  padding: 15px 20px;
  color: #fff;
  background-color: var(--primary-color);
  width: 100%;
  text-transform: capitalize;
  font-weight: 700;
  border-radius: 7px;
  transition: 0.3s;
}
header nav .right .cart button.checkout:hover {
  opacity: 0.6;
}

.core {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
  gap: 80px;
}

.checkout-rental-info-modal {
  top: 35% !important;
}

.checkout-rental-info-modal .rs-picker-menu {
  z-index: 9999 !important;
  width: 100%;
}
@media (max-width: 992px) {
  .core {
    gap: 70px;
  }
}
@media (max-width: 768px) {
  .core {
    width: unset;
    margin: unset;
    flex-direction: column;
    gap: unset;
    /* margin-bottom: 100px; */
  }
}

.description {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  text-align: left;
  /* gap: 10px; */
  width: 100%;
}
@media (max-width: 768px) {
  .description {
    padding: 10px;
    margin-top: 25px;
  }
}

.description p.pre {
  color: var(--primary-color);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 2px;
  margin: 0;
}

.description h1 {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 50px;
  margin: 0;
}

@media (max-width: 768px) {
  .description h1 {
    font-size: 35px;
    margin: 0;
  }
}

.description p.desc {
  font-weight: 500;
  margin: 0.5rem 0;
}
@media (max-width: 768px) {
  .description p.desc {
    line-height: 1.6;
    margin: 0;
  }
}

.description s {
  color: var(--grayish-blue);
}

.description .price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
@media (max-width: 768px) {
  .description .price {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
}

.description .price p {
  margin: 0;
}

.description .price .main-tag {
  display: flex;
  align-items: center;
  gap: 15px;
}
.description .price .main-tag p:first-of-type {
  font-size: 25px;
  font-weight: 700;
}

.description .price .main-tag p:last-of-type {
}

.description .price s {
  font-weight: 700;
}

.description .buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}
@media (max-width: 768px) {
  .description .buttons {
    flex-direction: column;
  }
  .description .buttons > * {
    width: 100%;
  }
}

.description .buttons .amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
  width: 180px;
  background-color: #f3a9502b;
  border-radius: 7px;
}
@media (max-width: 992px) {
  .description .buttons .amount {
    width: 150px;
  }
}
@media (max-width: 768px) {
  .description .buttons .amount {
    width: 100%;
  }
}

.description .buttons .amount .minus {
  display: flex;
}

.description .buttons .amount p {
  font-weight: 700;
  margin: 0;
}

.description .buttons .add-to-cart {
  padding: 15px 40px;
  background-color: var(--primary-color);
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex: 1;
  transition: 0.3s;
  /* box-shadow: 0px 30px 20px -8px var(--pale-orange); */
}
@media (max-width: 992px) {
  .description .buttons .add-to-cart {
    flex: unset;
    padding: 15px 20px;
  }
}
.description .buttons .add-to-cart:hover {
  opacity: 0.6;
}

.description .buttons .add-to-cart svg path {
  fill: #fff;
}

.gallery {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.gallery .image {
  max-width: 600px;
  max-height: 450px;
}

.gallery .image img {
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 300px;
}
@media (max-width: 992px) {
  .gallery .image img {
    height: 350px;
  }
}

.gallery .thumbnails {
  display: flex;
  align-items: center;
  gap: 20px;
}

.gallery .thumbnails .img-holder {
  position: relative;
  height: 90px;
  width: 90px;
}
@media (max-width: 992px) {
  .gallery .thumbnails .img-holder {
    height: 75px;
    width: 75px;
  }
}

.gallery .thumbnails img {
  height: 90px;
  border-radius: 10px;
  cursor: pointer;
  width: 90px;
  object-fit: cover;
}
@media (max-width: 992px) {
  .gallery .thumbnails img {
    height: 75px;
  }
}

.gallery .backdrop .backdrop-content {
  width: 415px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.gallery .backdrop .backdrop-content .image {
  position: relative;
}

.gallery .backdrop-content .image .icon-button-prev {
  position: absolute;
  left: -21px;
  top: 50%;
  transform: translateY(-50%);
}
.gallery .backdrop-content .image .icon-button-next {
  position: absolute;
  right: -21px;
  top: 50%;
  transform: translateY(-50%);
}

.gallery .backdrop .thumbnails {
  position: relative;
}

.gallery .backdrop .thumbnails .img-holder-backd {
  height: 90px;
  width: 90px;
  position: relative;
}

.gallery .backdrop .thumbnails img {
  height: 90px;
  width: 90px;
}

.MuiBadge-badge {
  background-color: hsl(26, 100%, 55%);
  font-weight: 700;
}
.MuiBadge-standard {
  font-weight: 700;
}

.mobile-gallery {
  position: relative;
  width: 100%;
}

.mobile-gallery img {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
}

.mobile-gallery button {
  position: absolute;
}

.mobile-gallery .icon-button-prev {
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.mobile-gallery .icon-button-next {
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.mobile-drawer {
  width: "65vw";
}

.mobile-drawer .closing {
  padding: 25px;
}
.mobile-drawer .mobile-links {
  padding-inline: 25px;
}

.mobile-drawer .mobile-links ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.mobile-drawer .mobile-links ul button {
  font-weight: 700;
  font-size: var(--p-fs);
}

.lease-con {
  background: #f3a9502b;
  padding: 1rem;
  width: 100%;
  border-radius: 10px;
}

.rs-picker-menu {
  z-index: 9999 !important;
}

.yarl__container {
  border-radius: 10px;
  background: #fff !important;
}

.rs-picker-popup {
  z-index: 9999 !important;
}

.equipment-details {
  border-top: none !important;
  border-radius: 15px !important;
}

.social-share-btn {
  color: var(--grey-color);
  cursor: pointer;
  transition: .3s ease;
}

.social-share-btn:hover {
  color: var(--black-color);
  transform: scale(1.1);
}
