.ref-card {
  background: var(--black-color);
  color: var(--white-color);
  padding: 1rem;
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  border-radius: 10px;
  position: relative;
}

.ref-line-divider {
  width: 5px;
  height: 50px;
  border-radius: 10px;
  background: var(--white-color);
}

.ref-btns {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 1rem;
  align-content: center;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0 0.5rem;
}
