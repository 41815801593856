.footer-con {
  background: #161b21;
  color: #fff;
  width: 100%;
}

.footer-con .footer-wrapper {
  padding: 2rem;
  padding-bottom: 0.5rem;
}

.footer-con .footer-row-1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #999;
  padding-bottom: 1rem;
}

.footer-con .footer-row-1 .right-side {
  display: flex;
  align-items: center;
}

.footer-con .footer-row-2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 0.5rem;
  text-align: right;
}

.footer-con .footer-row-2 .col,
.footer-con .footer-other-row-1 .col {
  padding: 1rem 1.5rem;
  margin: 1rem;
  padding-top: 0;
}

.footer-con .footer-row-2 .col h6,
.footer-con .footer-other-row-1 .col h6 {
  margin-bottom: 0.5rem;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
}

.footer-con .footer-row-2 .col ul,
.footer-con .footer-other-row-1 .col ul {
  list-style: none;
  list-style-type: none;
  padding-left: 0;
  color: #999;
}

.footer-con .footer-row-2 .col ul li a,
.footer-con .footer-other-row-1 .col ul li a {
  color: #999;
  transition: ease 0.5s;
}

.footer-con .footer-row-2 .col ul li,
.footer-con .footer-other-row-1 .col ul li {
  font-size: 16px;
  margin: 0.75rem 0;
  cursor: pointer;
}

.footer-con .footer-row-2 .col ul li a:hover,
.footer-con .footer-other-row-1 .col ul li a:hover {
  font-size: 16px;
  margin: 0.75rem 0;
  color: #f1f1f1;
}

.footer-con .footer-row-3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
}

.footer-con .footer-row-3 .social-media-icons {
  display: flex;
  align-items: center;
  transition: ease 0.5s;
}

.footer-con .footer-row-3 .social-media-icons svg {
  cursor: pointer;
}

.footer-con .footer-row-3 .social-media-icons svg:hover,
.footer-con .footer-other-row-2 svg:hover {
  color: #fff;
  cursor: pointer;
}

.footer-con .footer-other-row-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  /* border-bottom: 1px solid #999; */
  text-align: center;
}

.footer-con .footer-other-row-2 {
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-other-row-1 .vertical-divider {
  border-right: 1px solid #999;
  margin: 1rem;
  min-height: 170px;
}

.horizontal-divider {
  border-bottom: 1px solid #999;
  margin: 0.5rem auto;
  margin-top: 0;
  /* width: 75%; */
  max-width: 820px;
}

.footer-row-2 .vertical-divider {
  border-right: 1px solid #999;
  margin: 1rem;
  min-height: 140px;
}

.foooterLogo {
  margin: 0 2rem;
}

.ftr-link-div {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
}

.ftr-link-div a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: max-content;
  margin: 0 0.5rem;
}

@media screen and (max-width: 900px) {
  .footer-con .footer-row-2 {
    justify-content: center;
    flex-wrap: wrap;
  }

  .footer-con .footer-other-row-1 {
    flex-wrap: wrap;
    justify-content: center;
  }

  .vertical-divider {
    display: none;
  }

  .horizontal-divider {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .footer-con .footer-row-2 {
    justify-content: flex-start;
  }

  .footerLogo {
    margin: 0 1.5rem;
  }
}

@media screen and (max-width: 500px) {
  .footer-con .footer-row-2 .col {
    margin: 0.5rem;
    padding: 0.5rem;
  }
  .footer-con .footer-row-2 {
    justify-content: space-between;
  }

  .footer-con .footer-wrapper {
    padding: 0.5rem;
  }
  .footerLogo {
    margin: 0 1rem;
  }
}
