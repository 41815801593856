.dTuydp {
  display: none !important;
}
.khlYMD {
  background-color: #fbe3c6 !important;
  color: #fff !important;
  border-radius: 5px 5px 0 0 !important;
}

.sc-bXCLTC.jgEkYS {
  color: var(--black-color) !important;
}

.upload-rental-ins-con {
  padding: 2rem;
  background-color: #fcefde;
  border-radius: 30px;
  max-width: 1200px;
  padding-bottom: 0.5rem;
}

.checkout-file-upload-con .kb-file-upload {
  background: #fbe3c6 !important;
  border: 0px dashed var(--grey-color);
  border-radius: 8px;
  border: 1px dashed var(--primary-color);
  z-index: 1;
}
