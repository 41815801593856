.custom-file-upload input[type="file"] {
  display: none;
}

.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 4px;
  cursor: pointer;
  background: transparent;
  border: 2px solid var(--grey-color);
}

.img-wrap {
  position: relative;
  width: 135px;
  height: 135px;
  overflow: hidden;
  border-radius: 50%;
}
.img-upload:before {
  content: "Upload Image";
  font-size: 16px;
  position: absolute;
  padding-top: 90px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: var(--grey-color);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
}
.img-upload:hover:before {
  opacity: 1;
}
.img-upload img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
