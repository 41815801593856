.vertical-divider-header {
  border-right: 1px solid #999;
  margin: 1rem;
  height: 30px;
}

.godizel-short-header-img {
  display: none;
}

.header-dropdown {
  left: -5px !important;
  /* padding: 0 !important;
  width: 100% !important;
  left: 5px !important;
  right: 0 !important; */
  /* display: block !important; */
  /* border-top: 1px solid var(--grey-color) !important; */
}

.header-buttons {
  color: var(--white-color) !important;
}

.header-transition-con {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.header-transition-con.open {
  transform: translateY(0);
}
.header-dropdown {
  position: relative;
}

.label {
  color: white;
  cursor: pointer;
  display: flex;
}

.slide {
  position: fixed;
  top: 3.85rem;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height 0.4s ease;
  display: flex;
  flex: 1;
  justify-content: center;
}

header:hover:not(.no-slide-hover) .slide {
  display: flex;
  flex: 1;
  justify-content: center;
  height: 182px; /* Adjust the height as needed */
}

.elastic-navbar-search .MuiAutocomplete-inputFocused {
  border: none !important;
}

.elastic-search-icon {
  display: none;
}

@media screen and (max-width: 1070px) {
  .elastic-navbar-search {
    display: none;
  }
  .elastic-search-icon {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
  }
}

@media screen and (max-width: 760px) {
  header:hover .slide {
    height: 375px;
  }
}

@media screen and (max-width: 600px) {
  .slide {
    top: 3.5rem;
  }
}

@media screen and (max-width: 640px) {
  .godizel-short-header-img {
    display: flex;
  }
  .vertical-divider-header {
    display: none !important;
  }
}

.nested-dropdown {
  position: relative;
  display: inline-block;
}

.nested-dropdown-button {
  font-family: "Helvetica", Arial, "sans-serif" !important;
  font-weight: 700;
  color: var(--white-color);
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  margin: 0.75rem 0;
  border-radius: 5px;
  position: relative;
}
.nested-dropdown-button:hover {
  background: var(--dark-grey-color);
  transition: 0.3s ease;
}
.nested-dropdown-list {
  /* top: 100%; */
  left: 0;
  /* display: none; */
  list-style: none;
  z-index: 1000;
  font-family: "Helvetica", Arial, "sans-serif" !important;

  position: absolute;
  background-color: var(--black-color);
  /* border-top: 1px solid var(--grey-color);
  border-bottom: 1px solid var(--grey-color); */
  /* border-right: 1px solid var(--grey-color); */
  /* border-left: 1px solid var(--grey-color); */
  /* border-top: 2px solid var(--grey-color); */
  /* -webkit-box-shadow: 0px -5px 10px -5px rgba(255, 255, 255, 0.75);
  -moz-box-shadow: 0px -5px 10px -5px rgba(255, 255, 255, 0.75);
  box-shadow: 0px -5px 10px -5px rgba(255, 255, 255, 0.75); */
  min-width: 375px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.25s ease, transform 0.25s ease, visibility 0.25s;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  margin: 0;
  min-height: 420px;

  /* top: 100%; */
  /* margin: 0 0.15rem; */
  /* top: 3.25rem; */
  /* right: 0; */
}

.nested-dropdown-list:first-child {
  border-radius: 0 0 0 10px;
}

/* .nested-dropdown-list:last-of-type {
  border-radius: 0 0 10px 0;
} */

.nested-dropdown:hover .nested-dropdown-list,
.nested-dropdown-item:hover .nested-dropdown-submenu,
.nested-dropdown-item:focus .nested-dropdown-submenu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  border-radius: 3px;
}

.nested-dropdown-item {
  /* position: relative; */
  padding: 4px;
  min-width: auto;
}

.nested-dropdown-item a {
  text-decoration: none;
  transition: 0.3s ease;
  font-family: "Helvetica", Arial, "sans-serif" !important;
  font-size: 16px;
  color: var(--white-color);
  padding: 10px 20px;
  border-radius: 3px;
  text-decoration: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nested-dropdown-item a:hover {
  transition: 0.3s ease;
  color: var(--white-color) !important;
  background-color: var(--secondary-color) !important;
  /* f3734f */
}

.nested-dropdown-item:hover .nested-dropdown-list,
.nested-dropdown:hover .nested-dropdown-list {
  display: block;
}

.nested-dropdown-list .nested-dropdown-list {
  top: 0;
  left: 100%;
}
