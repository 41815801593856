.search-container {
  position: relative;
  width: 300px;
  transition: width 0.3s ease;
}

.search-container.expanded {
  width: 350px; /* Adjust the width as needed */
}

.search-input {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  /* border: 1px solid var(--grey-color); */
  border: none;
  background: var(--dark-grey-color) !important;
  color: var(--white-color);
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
  padding-right: 20px;
}

.search-input.not-visible {
  border-radius: 5px;
  /* border: 1px solid var(--grey-color); */
}

.search-input.visible {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  /* border: none; */
}

.search-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  background-color: var(--dark-grey-color);
  color: var(--white-color);
  /* border: 2px solid #ccc; */
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-sizing: border-box;
  transition: max-height 0.5s ease;
  z-index: 10;
}

.search-suggestions.visible {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 2px solid var(--grey-color);
}

.search-suggestions ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-suggestions li {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
}

/* .search-suggestions li:hover {
  background-color: #f1f1f1;
} */

.search-suggestions li.selected,
.search-suggestions li:hover {
  background-color: #333;
}

.loader {
  border: 2px solid #f1f1f1;
  border-top: 2px solid var(--black-color);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  margin: 10px auto;
}

.search-suggestions::-webkit-scrollbar {
  width: 12px; /* Adjust width as needed */
}

.search-suggestions::-webkit-scrollbar-track {
  border-radius: 5px; /* Match border-radius of container */
  background: #f1f1f1; /* Background color of the track */
}

.search-suggestions::-webkit-scrollbar-thumb {
  border-radius: 5px; /* Match border-radius of container */
  background: #888; /* Color of the scrollbar thumb */
}

.clear-button {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  display: none;
  outline: none;
  background: var(--grey-color);
  color: var(--black-color);
  border-radius: 50%;
}

.search-input:focus + .clear-button,
.search-input:not(:placeholder-shown) + .clear-button {
  display: block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
