.spinner_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* 100% of the viewport height */
}

.spinner_container {
  width: 250px;
  margin: auto;
}
