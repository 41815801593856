.checkmark-con {
  display: flex;
  align-items: center;
  justify-self: center;
}

.checkmark-content {
  margin-left: 0.5rem;
}

.order-conf-table-con {
  margin: 1rem 0;
}

.order-conf-table-con table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #f1f1f1;
}

.order-conf-table-con th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  width: 100%;
  /* white-space: nowrap; */
}

.order-conf-table-con th {
  /* background-color: #f2f2f2; */
  border: none;
  border-bottom: 1px solid var(--grey-color);
}

.order-conf-table-con td.tbl-header {
  /* background-color: #f2f2f2; */
  font-weight: bold;
}

.order-conf-table-con td {
  border: none;
  border-bottom: 1px solid var(--grey-color);
}

.order-conf-vertical-divider {
  height: 500px;
  border-right: 1px solid var(--black-color);
}

.order-conf-con {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 100vh;
  padding: 1rem 0;
  padding-bottom: 2rem;
}

.order-conf-con .left {
  flex: 100%;
  padding: 0 2rem;
  border-right: 1px solid var(--black-color);
}

.order-conf-con .right {
  flex: 40%;
  padding: 1rem 2rem;
  /* border-left: 1px solid var(--black-color); */
}

.order-conf-products-con {
  margin: 2rem 0;
}

.order-conf-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

.confetti-container {
  transition: opacity 2000ms, visibility 2000ms, height 2000ms; /* 4s duration for the fade-out effect */
}

.confetti-container.hide {
  /* opacity: 0;
  pointer-events: none; 
  display: none;
  transition: opacity 1000ms;  */
  opacity: 0;
  pointer-events: none; /* Disable pointer events when hidden */
  visibility: hidden;
  height: 0;
  display: none;
}

.confetti-container.show {
  /* opacity: 1;
  display: block;
  height: 100%;
  transition: opacity 1000ms; */
  display: inherit;
  opacity: 1;
  pointer-events: all;
  visibility: visible;
  height: 100%;
  z-index: 99999;
}
