.cons-info-card {
  width: 100%;
  padding: 1rem;
  background: var(--white-color);
  color: var(--black-color);
  max-width: 260px;
  border-radius: 10px;
  /* background: #f1f1f1; */
  /* border: 1px solid #f1f1f1; */
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 4px 0px;
  transition: ease 0.5s;
}

.cons-info-card:hover {
  transition: ease 0.5s;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
