/* Header */
.adm-header {
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
  padding: 20px;
  background-color: var(--primary-color);
  color: var(--white-color);
  position: fixed;
  top: -1px;
  width: 100%;
  z-index: 11;
}

.adm-header .header-logo {
  font-size: 24px;
  text-align: left;
  padding: 10px;
}

.adm-header .header-logo .nav {
  margin-left: auto;
}

.adm-header .header-logo .nav .nav__list {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adm-header .header-logo .nav .nav__list .nav__item {
  margin-right: 20px;
}

.adm-header .header-logo .nav .nav__list .nav__item:last-child {
  margin-right: 0;
}

.adm-header .header-logo .nav .nav__list .nav__item a {
  color: var(--white-color);
  text-decoration: none;
}

/* Sidebar Header */
.sidebar-header {
  /* display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: var(--black-color);
  color: var(--white-color);
  position: sticky;
  top: 0%;
  width: 100%;
  z-index: 999; */

  align-items: center;
  background-color: var(--black-color);
  color: var(--white-color);
  display: flex;
  justify-content: space-between;
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.sidebar-header .logo {
  font-size: 24px;
}
