.cp-card {
  width: 100%;
  /* padding: 1rem; */
  /* border: 1px solid #f1f1f1; */
  margin: 1rem 0;
  border-radius: 5px;
  transition: 0.4s ease;
}

.cp-card .cp-row-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
  position: relative;
}

.cp-card .cp-row-1 .cp-image {
  width: 100%;
  max-width: 250px;
  max-height: 250px;
  border-radius: 5px;
}

.cp-card .cp-row-1 .cp-image img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.cp-card .cp-row-1 .cp-info {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 1rem;
}

.cp-card .cp-row-1 .cp-ver-divider {
  height: 200px;
  width: min-content;
  border-right: 1px solid #ddd;
}

.cp-card .cp-row-1 .cp-price {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 1rem;
  position: relative;
}

.cp-card .cp-row-1 .cp-price .cp-side-actions {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: flex-end;
}

.cp-card .cp-row-1 .cp-price .cp-side-actions > svg {
  cursor: pointer;
}

.cp-card .cp-row-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  /* transition: 0.4s ease; */
  /* opacity: 0; */
}

.cp-card .cp-row-2 .cp-contact-seller {
  /* border-bottom: 1px solid var(--grey-color); */
  cursor: pointer;
}

.cp-card .cp-row-2 .cp-actions {
  display: flex;
}

.cp-card .cp-row-2 .cp-actions > svg {
  margin: 0 0.5rem;
  color: var(--secondary-color);
  cursor: pointer;
}

.normal-text {
  font-weight: normal !important;
}

/* .cp-card:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fefefe;
} */

@media screen and (max-width: 1200px) {
  .cp-card {
    width: fit-content;
    max-width: 300px;
  }

  .cp-card .cp-row-1 {
    max-width: 250px;
  }
  .cp-row-1 {
    flex-direction: column;
  }

  .cp-image {
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .cp-card .cp-row-1 .cp-info {
    width: 100%;
    margin: auto;
    padding-left: 0;
    padding: 0.5rem;
    padding-top: 0;
  }

  .cp-ver-divider {
    display: none;
  }

  .cp-card .cp-row-1 .cp-price {
    width: 100%;
    margin: auto;
    padding: 0.5rem;
  }

  .cp-card .cp-row-1 .cp-info {
    width: 100%;
  }

  .cp-card .cp-row-1 .cp-image {
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .cp-card .cp-row-1 .cp-price {
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .cp-card {
    width: 100%;
    max-width: none;
  }
  .cp-card .cp-row-1 {
    max-width: none;
    width: 100%;
  }
  .cp-card .cp-row-1 .cp-image {
    width: 100%;
    max-width: 100%;
    max-height: 300px;
    border-radius: 5px;
  }
}

/* .cp-card:hover .cp-row-2 {
  opacity: 1;
  transition: 0.4s ease;
} */
