.trending-car-card {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 999999;
  /* background: linear-gradient(
    180deg,
    hsla(0, 0%, 0%, 0.1) 0%,
    hsla(0, 0%, 0%, 0.5) 30%,
    hsla(0, 0%, 0%, 0.7) 100%
  ); */
  background: var(--black-color);
  border-radius: 10px;
  padding: 1rem;
  min-width: 100px;
  min-height: 60px;
  margin: auto;
  text-align: center;
  opacity: 0;
  transition: 0.5s ease;
  color: var(--white-color);
}

.trending-car-card .brand {
  color: var(--white-color);
}

.trending-car-card .year-of-manufacture {
  color: var(--grey-color);
  font-weight: 100;
}

.car-card:hover .trending-car-card {
  opacity: 1;
  transition: 0.5s ease;
}
