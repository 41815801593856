@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,600|Roboto+Mono:300,400");
* {
  box-sizing: border-box;
}

.main {
  background: linear-gradient(rgba(34, 41, 65, 0.55), rgba(46, 55, 86, 0.75)), url("../../assets/Pictures/login-bg-2.jpg");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 50px);
  overflow: hidden;
}

.hero-head header {
  height: 75px;
}
.hero-head header .navbar-item img {
  height: 75px;
  max-height: none;
}
.hero-head header .navbar-item .is-primary {
  border-color: white !important;
  color: white !important;
}
.hero-head header .navbar-item .is-primary:hover {
  background: rgba(0, 0, 0, 0) !important;
  border-color: white !important;
  color: white !important;
  transition: all 0.2s ease-in-out;
}

.hero-body {
    text-align: center;
}

.hero-body .title {
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
  font-size: 4rem !important;
  letter-spacing: 15px;
  color: #ffffff !important;
}

.rotating{
  animation:spin 2s linear infinite;
}

@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}

.hero-body .subtitle .countdown {
  color: #ffffff;
  font-family: "Roboto Mono", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  padding-top: 15px;
  letter-spacing: 2px;
  font-size: 2.5rem;
}

.hero-foot {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  position: absolute;
  bottom: 2rem;
  width: 100%;
}
.hero-foot ul li {
  display: inline;
  padding: 25px;
}
.hero-foot a {
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.7rem;
}
.text-subscribe {
    color: #fff !important;
    text-align: center;
}

/* -------------------- */
/* -- Animations CSS -- */
/* -------------------- */
@keyframes fadeIn {
  from {
    opacity: 0;
    opacity: 1\9 ;
  }
  to {
    opacity: 1;
  }
}
.fade {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 2s;
}

.fade.fadeOne {
  animation-delay: 1.7s;
}

.fade.fadeTwo {
  animation-delay: 2.2s;
}

.fade.fadeThree {
  animation-delay: 3.7s;
}
.fade.fadeThree span {
  font-size: 0.8rem;
}
.subtitle input[type="email"] {
    display: inline-block;
    background: rgba(255, 255, 255, .2);
    width: 40%;
    padding: 12px 20px;
    margin: 8px 0;
    color: #fff;
    /* border: 1px solid #ccc; */
    box-sizing: border-box;
    height: 50px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.06);
    position: relative
  }
  .subtitle input,
  input::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 3;
  }

  input[type="email"]:focus-visible {
    border: 0px solid transparent !important;
    outline: none;
  }
  
  .bt {
    display: flex;
    justify-self: center;
    align-items: center;
    justify-content: center;
    position: relative;
    right: 3.5rem;
    top: .1rem;
    color: #fff;
  }

  ::placeholder {
    color: #ddd;
    opacity: 1; /* Firefox */
  }
  
  ::-ms-input-placeholder { /* Edge 12 -18 */
    color: #ddd;
  }

  @media screen and (max-width: 640px){
    .subtitle input[type="email"] {
        width: 80%;
        margin-left: 1rem;
    }
  }

