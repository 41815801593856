.landing-section {
  display: flex;
  /* height: 75vh; */
  justify-content: space-between;
  align-items: center;
  /* width: 85%; */
  /* margin: auto 4rem; */
}

.gradient-text {
  /* background: linear-gradient(to right, #f26654 0%, #28607c 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; */
  line-height: 1.3;
  color: var(--black-color);
}

.element {
  display: inline-block;
  padding-bottom: 3px;
}

.element:after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  animation: underline 4s infinite;
}
@keyframes underline {
  0% {
    width: 0%;
    background-color: var(--primary-color);
  }
  100% {
    width: 100%;
    background-color: transparent;
  }
}

.landing-section .landing-sec-right,
.landing-section .landing-sec-left {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  flex: 50%;
}

.landing-section .landing-sec-left {
  /* margin-top: 3rem; */
  /* justify-content: flex-start;
  align-items: flex-start; */
}

.landing-section .landing-sec-right {
  /* width: 80%; */
  /* padding-right: 4rem;
  margin-top: 2rem; */
  /* justify-content: flex-end; */
  /* padding-bottom: 5rem; */
}

.landing-sec-right .landing-sec-logo {
  width: 150px;
  margin-bottom: 2rem;
}

.landing-sec-right .landing-sec-btn-con {
  display: flex;
  justify-content: center;
  /* width: 50%; */
  margin-top: 2rem;
}

.landing-sec-right .heading {
  font-size: 48px;
  font-weight: 700;
  color: var(--secondary-color);
}

.landing-sec-right .desc {
  /* font-size: 24px; */
  line-height: 1.2;
  margin: 0.5rem;
}

.landing-sec-right .btn {
  margin: 0 1rem;
  text-decoration: none !important;
  min-width: 150px;
  border-radius: 3px;
}

.landing-sec-left .img-con {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  max-width: 500px;
  /* max-height: 300px; */
}

.landing-sec-left .img-con {
  /* box-shadow: rgba(242, 102, 84, 0.5) -5px 5px, rgba(242, 102, 84, 0.4) -10px 10px, rgba(242, 102, 84, 0.3) -15px 15px, rgba(242, 102, 84, 0.2) -20px 20px, rgba(242, 102, 84, 0.1) -25px 25px;
  
    -webkit-box-shadow:0px 0px 47px 5px rgba(130,130,130,0.62);
  -moz-box-shadow: 0px 0px 47px 5px rgba(130,130,130,0.62);
  box-shadow: 0px 0px 47px 5px rgba(130,130,130,0.62); */

  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
  margin: autos;
}

.landing-sec-left .img-con img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1300px) {
  .landing-section .landing-sec-right {
    padding-right: 1rem;
  }
}

@media screen and (max-width: 1150px) {
  .landing-section .landing-sec-right {
    padding-right: 0rem;
  }
}

@media screen and (max-width: 950px) {
  .landing-section {
    padding: 2rem 1.5rem;
    flex-direction: column-reverse;
    height: auto;
  }
  .landing-section .landing-sec-left,
  .landing-section .landing-sec-right {
    width: 100%;
  }
  .landing-section .landing-sec-left {
    margin-top: 2rem;
  }
  .landing-sec-right .heading {
    font-size: 40px;
  }
  .landing-sec-right .landing-sec-btn-con {
    /* width: 80%; */
  }
  .landing-sec-right .desc {
    /* font-size: 18px; */
  }
  .landing-sec-left .img-con {
    width: 80%;
  }
  .landing-sec-right .landing-sec-logo {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .landing-section {
    min-height: 50vh;
    justify-content: start;
    margin: 0;
  }
  .landing-sec-left .img-con {
    width: 100%;
  }
  .landing-sec-right .btn {
    min-width: min-content;
  }

  .landing-section .landing-sec-left {
    padding: 1rem;
  }
}
