.shop-page-card {
  width: 320px;
  /* margin: 1rem; */
  border-radius: 10px;
  transition: ease 0.5s;
  /* cursor: pointer; */
  /* height: 250px; */
}

.shop-page-card .img-con {
  border-radius: 10px 10px 0 0;
  position: relative;
  width: 100%;
  margin: auto;
  height: 210px;
  background: var(--black-color);
  padding: 0.25rem;
  padding-bottom: 0;
}

.shop-page-card .img-con img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  background: #fff;
  border-radius: 6px 6px 5px 5px;
  min-height: 210px;
  min-width: 250px;
  margin: auto;
  height: 100%;
}

.shop-page-card .img-con .heart {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 111;
}

.shop-page-card .img-con .hold_draft {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 111;
}

.shop-page-card .text-content {
  padding: 1rem 0.5rem;
  padding-bottom: 0.5rem;
  background: transparent;
  border-radius: 0 0 10px 10px;
  transition: ease 0.5s;
  position: relative;
  background: var(--black-color);
  /* padding-bottom: 0.5rem; */
}

.shop-page-card:hover .text-content {
  /* background: #212121; */
  transition: ease 0.5s;
}

.shop-page-card .heart {
  opacity: 1;
  color: var(--white-color);
}

.shop-page-card:hover .heart {
  opacity: 1;
  transition: 0.5s ease;
}

.shop-page-card .text-content .title,
.shop-page-card .text-content .location {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  position: relative; /* Add relative positioning */
}

.shop-page-card .text-content .dot {
  height: 5px;
  width: 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0 0.5rem;
}

.shop-page-card .text-content .row-2 .price {
  /* font-size: 16px; */
  margin: 0.2rem;
}

.shop-page-card .text-content .row-2 .subprice {
  /* font-size: 14px; */
  color: "var(--grey-color)";
  font-weight: normal;
  margin: 0.2rem;
}

.shop-page-card .text-content .chip-con {
  position: absolute;
  top: -1rem;
  right: 1rem;
}

.shop-page-card .text-content .chip-con .chip {
  padding: 5px;
  min-width: 50px;
  background-color: var(--white-color);
  color: var(--black-color);
  font-size: 14px;
  font-weight: 700;
  margin: 0 0.3rem;
}

.shop-page-card .text-content .chip-con .chip-info {
  padding: 5px;
  background-color: var(--white-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: var(--black-color);
  font-size: 14px;
  font-weight: 700;
  margin: 0 0.3rem;
  cursor: pointer;
}

.shop-page-card .text-content .chip-con .chip-info span {
  padding: 0px !important;
  line-height: 12px !important;
}

.shop-page-card .img-con .hold_draft .chip {
  padding: 5px;
  min-width: 50px;
  background-color: var(--white-color);
  color: var(--black-color);
  font-size: 14px;
  font-weight: 700;
  margin: 0 0.3rem;
}

.shop-page-card .img-con .hold_draft .chip-info {
  padding: 5px;
  background-color: var(--white-color);
  color: var(--black-color);
  font-size: 14px;
  font-weight: 700;
  margin: 0 0.3rem;
  cursor: pointer;
}

.shop-page-card .img-con .hold_draft .chip-info span {
  padding: 0px !important;
  line-height: 12px !important;
}

.shop-page-card .img-con .dizel-bolt {
  position: absolute;
  top: 1rem;
  left: 1rem;
  /* background: var(--black-color); */
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.shop-page-card .img-con .dizel-bolt img {
  width: auto !important;
  min-width: 0 !important;
  min-height: 0 !important;
}

.shop-page-card .img-con .third-party-logo {
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: var(--white-color);
}

.shop-page-card .img-con .third-party-logo img {
  width: 100% !important;
}
/* 
.MuiTooltip-tooltipArrow {
  margin-left: -10px !important;
} */

@media screen and (max-width: 787px) {
  .shop-page-card {
    width: 340px !important;
  }
}


@media screen and (max-width: 500px) {
  .shop-page-card {
    width: 320px !important;
  }
}
