.signup_con {
  margin: 0;
  padding: 0;
  background-image: linear-gradient(
      rgba(124, 124, 124, 0.1),
      rgba(0, 0, 0, 0.6)
    ),
    url("../../assets/Pictures/login-bg.png");
  position: relative;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup_con .signup_wrapper {
  width: 90%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup_con .signup_wrapper .left {
  flex: 40%;
  padding: 2rem;
}

.signup_con .signup_wrapper .right {
  width: 100%;
  max-width: 550px;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 29px 0px;
  padding: 2rem 3rem;
}

.row {
  display: flex;
}

.css-13cymwt-control {
  background: transparent !important;
}

.css-13cymwt-control:focus {
  background: transparent !important;
}

@media screen and (max-width: 925px) {
  .signup_con .signup_wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 640px) {
  .signup_con .signup_wrapper .left {
    padding: 0;
  }
  .signup_con .signup_wrapper .right {
    padding: 2rem 1rem;
  }
}
