.add-new-item-wrapper {
  width: 100%;
  margin: auto;
  padding: 2rem 4rem;
}
.item_title {
  color: #666;
  padding-bottom: 10px;
  font-size: 18px !important;
}

.preview_image_container {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.preview_image_container img {
  width: 300px;
}
.img_name_cont {
  width: 100%;
  text-align: center;
  color: #666;
  gap: 1rem;
}

.img_name_cont i {
  font-size: 20px;
}

.img_name_cont .img_icon_cont {
  margin: 0px 5px;
  cursor: pointer;
}

.img_name_cont .img_icon_cont img {
  border: 4px solid transparent;
  aspect-ratio: 3/2;
  object-fit: contain;
}

.img_name_cont .img_icon_cont .img_name {
  position: relative;
}

.img_name_cont .img_icon_cont .cross {
  position: absolute;
  z-index: 0;
  top: -6%;
  background: #444;
  color: white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  /* font-weight: bold; */
  right: -4%;
}

.img_name_cont .img_icon_cont img.feature {
  border: 4px solid #90caf9;
  border-radius: 10px;
}

.img_upload_container .file_icon_container {
  width: 100%;
}
.img_upload_container .file_icon_container label {
  width: 100%;
  border: 1px solid #444;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: block;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.drag-active {
  background-color: #f1f1f1;
}
.add_icon_container {
  text-align: center;
  padding-top: 30px;
}
.item_title {
  color: #808080;
  font-family: "Helvetica", Arial, sans-serif;
  padding-bottom: 10px;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding: 16.5px 9px 15px 10px !important;
  z-index: 0 !important;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #000 !important;
  font-size: 16px !important;
}
.rs-picker-toggle-caret.rs-icon {
  top: 15px !important;
  right: 15px !important;
}
.rs-stack-item svg {
  top: 15px !important;
}
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 15px !important;
  right: 15px !important;
}
.rs-picker-error > .rs-input-group {
  border-color: #666 !important;
  padding: 14px;
}

.responsive-icon {
  font-size: 18px;
}
@media screen and(max-width: 787px) {
  .responsive-icon {
    font-size: 16px;
  }
}
@media screen and (max-width: 640px) {
  .add-new-item-wrapper {
    padding: 0 1rem;
    margin-top: 2rem;
  }
  .rs-picker-daterange-content {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  .rs-calendar {
    max-width: 70% !important;
  }
  .rs-stack {
    max-width: 380px !important;
    min-width: none !important;
  }
  .rs-stack-item {
    width: 100% !important;
  }
  .rs-picker-daterange-calendar-group {
    max-width: 380px !important;
    min-width: none !important;
  }
  .rs-picker-menu .rs-calendar {
    max-width: 380px !important;
    min-width: 0 !important;
  }
  .rs-picker-daterange-menu .rs-calendar {
    width: 100% !important;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 15px !important;
  }
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 15px !important;
  }
  .rs-picker-toggle-caret .rs-icon {
    top: 15px !important;
  }

  .rs-anim-fade
    .rs-anim-in
    .rs-picker-daterange-menu
    .rs-picker-menu
    .placement-bottom-start {
    width: 90% !important;
  }
  .rs-picker-menu {
    width: 90% !important;
  }
  .rs-picker-daterange-calendar-group {
    min-width: 0 !important;
    max-width: 380px !important;
  }
 
    .responsive-icon {
      font-size: 14px;
    }
  
}

@media screen and (max-width: 600px) {
  .item_title {
    font-size: 14px !important;
  }
}
