.abt-sec-1-article {
    background: linear-gradient(to right, var(--black-color), rgba(0, 0, 0, 0.1)),
      url("../../assets/Pictures/about-1.png");
    /* height: calc(100vh - 60px); */
    height: 60vh;
    max-height: 1080px;
    width: 100%;
    max-width: 100%;
    position: relative;
    object-fit: fill;
    background-repeat: no-repeat;
    /* max-width: 1920px; */
    margin: auto;
    background-position: top;
    background-size: cover;
    border-radius: 10px;
    margin-top: 2rem;
  }
  
  .abt-sec-1-content {
    /* position: absolute;
    top: 35%;
    left: 10rem; */
    color: var(--white-color);
    width: 100%;
    padding: 0 2.5rem;
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    justify-content: center;
  }
  
  .abt-sec-2 {
    display: flex;
    padding: 1rem;
    min-height: 60vh;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: auto;
  }
  
  .abt-sec-2 .col {
    width: 50%;
    margin: 0.5rem;
    /* text-align: center; */
  }
  
  .abt-sec-2 .col.text-content.left {
    text-align: left;
  }
  
  /* .abt-sec-2 .col.text-content.right {
    text-align: right;
  } */
  
  .abt-sec-2 .col video {
    max-width: 500px;
  }
  
  .abt-sec-2 .col img {
    width: 100%;
    border-radius: 10px;
  }
  
  .abt-search {
    /* width: 50%; */
    box-sizing: border-box;
    margin-right: 0;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
  
  .abt-search * {
    outline: none;
    box-sizing: border-box;
  }
  
  .search__wrapper {
    position: relative;
  }
  
  .search__field {
    width: 100%;
    color: #ddd;
    font-family: "Lato", sans-serif;
    font-size: 1.5em;
    /* padding: 0.35em 50px 0.35em 0; */
    border: 1px solid transparent;
    border-radius: 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .search__field:focus {
    border-bottom-color: #ccc;
    color: #2b2b2b;
    cursor: default;
  }
  
  .search__field:focus ~ .search__icon {
    background-color: transparent;
    cursor: pointer;
    pointer-events: auto;
  }
  
  .search__icon {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #e9f1f4;
    width: 50px;
    height: 50px;
    font-size: 1.35em;
    text-align: center;
    border-color: transparent;
    border-radius: 50%;
    pointer-events: none;
    display: inline-block;
    transition: background-color 0.2s ease-in-out;
  }
  
  .search__field::-webkit-input-placeholder,
  .search__field:-moz-placeholder,
  .search__field::-moz-placeholder,
  .search__field:-ms-input-placeholder {
    position: relative;
    top: 0;
    left: 0;
    transition-property: top, color;
    transition-duration: 0.1s;
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000;
  }
  
  .search__field::-webkit-input-placeholder[style*="hidden"],
  .search__field:-moz-placeholder[style*="hidden"],
  .search__field::-moz-placeholder[style*="hidden"],
  .search__field:-ms-input-placeholder[style*="hidden"] {
    color: #83b0c1;
    font-size: 1.2em;
    font-weight: normal;
    top: -20px;
    opacity: 1;
    visibility: visible !important;
  }
  
  @media screen and (max-width: 1020px) {
    .abt-sec-2 {
      max-width: none;
      flex-wrap: wrap;
      width: 100%;
    }
    .abt-sec-2 .col {
      width: 80%;
      text-align: center;
    }
    .abt-sec-2 .col video {
      max-width: 400px;
      width: 100%;
    }
  }
  
  @media screen and (max-width: 640px) {
    .abt-sec-1-content {
      left: 0;
      padding: 1rem;
    }
    .abt-sec-2 .col {
      width: 100%;
    }
    .abt-sec-1-article {
      margin-top: 0;
      border-radius: 0;
    }
    .abt-sec-2 {
      padding: 1rem 0;
    }
  }
  