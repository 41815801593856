/* ShopFilter.css */

.filter-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0.5rem;
  margin-right: 1.5rem;
}

.slider-wrapper {
  margin-bottom: 16px;
}

/* .css-1f3w2yg-MuiSlider-thumb {
  width: 10px !important;
  height: 10px !important;
} */

.last-accordion {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.filter-slider-btn {
  width: 45px !important;
  height: 30px !important;
  font-size: 10px !important;
  padding: 3px !important;
  border: 1px solid var(--grey-color);
  border-radius: 3px;
  margin-bottom: 0.5rem;
  margin-top: 0;
  text-align: center;
  color: var(--dark-grey-color);
  transition: 0.5s ease;
  /* border: none; */
}

.filter-slider-btn:focus {
  border: 1px solid var(--primary-color);
}

.filter-options-label {
  font-size: 16px !important;
  margin: 0 !important;
}

.filter-acc-details {
  padding: 5px !important;
  padding-top: 0 !important;
  padding-bottom: 1rem !important;
}

.subcategory-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.subcategory-container.open {
  max-height: 1000px; /* Adjust as needed */
}

@media (max-width: 600px) {
  .filter-container {
    padding: 0;
    margin: 0;
    margin-right: 0;
    margin-top: 1rem;
  }
}
